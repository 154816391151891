import FusePageCarded from '@fuse/core/FusePageCarded';
import withReducer from 'app/store/withReducer';
import { styled } from '@mui/material/styles';
import CustomDataGrid from 'app/shared-components/CustomDataGrid';
import { useDispatch, useSelector } from 'react-redux';
import { isUserBillingAdmin } from 'app/services/permissionUtil';
import reducer from '../store';
import { openEditAccountDialog, openNewAccountDialog } from '../store/accountsSlice';
import AccountDialog from './AccountDialog';
import FuseLoading from '../../../../@fuse/core/FuseLoading';
import PageHeader from '../../../shared-components/PageHeader';
import BillingSpinner from '../BillingSpinner';
import { filterByMnoStatus } from '../utils';
import { useGetAccountsInvalidatedQuery } from '../store/accountsApi';
import { useGetMnosInvalidatedQuery } from '../store/mnosApi';

const Root = styled(FusePageCarded)(({ theme }) => ({
  '& .FusePageCarded-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 136,
      height: 136,
    },
  },
  '& .FusePageCarded-content': {
    display: 'flex',
  },
  '& .FusePageCarded-contentCard': {
    overflow: 'hidden',
  },
}));

const columns = [
  { field: 'id', headerName: 'ID', width: 50 },
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'mno', headerName: 'MNO', flex: 1 },
  { field: 'email', headerName: 'Email', flex: 1 },
  { field: 'amountDueLimit', headerName: 'Amount Due Limit', flex: 1 },
  { field: 'amountDueLimitCurrency', headerName: 'Amount Due Limit Currency', flex: 1 },
];

function Accounts() {
  const dispatch = useDispatch();
  const { data, isLoading,isFetching, refetch } = useGetAccountsInvalidatedQuery();
  const { data: mnos = [] } = useGetMnosInvalidatedQuery();
  const accounts = mnos && data && filterByMnoStatus(mnos, data);

  const user = useSelector(({ auth }) => auth.user);
  const isBillingAdmin = isUserBillingAdmin(user);

  const handleRefresh = () => {
    refetch();
  };

  return (
    <>
      <Root
        header={
          <PageHeader
            iconName="account_box"
            title="Accounts"
            buttonName="New Account"
            noHeaderButton={!isBillingAdmin}
            onButtonClick={() => dispatch(openNewAccountDialog())}
          />
        }
        content={
          isLoading || isFetching ? (
            <FuseLoading />
          ) : (
            <CustomDataGrid
              name="accounts"
              rows={accounts}
              columns={columns}
              density="compact"
              onRefresh={handleRefresh}
              onRowClick={(newSelectionModel) => {
                if (isBillingAdmin) dispatch(openEditAccountDialog(newSelectionModel.row));
              }}
            />
          )
        }
        innerScroll
      />
      <AccountDialog />
      <BillingSpinner />
    </>
  );
}

export default withReducer('billing', reducer)(Accounts);
