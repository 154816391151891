import { API } from 'aws-amplify';

export function filterByMnoStatus(
  mnos,
  data,
  validMnoStatuses = ['testing', 'deployed', 'retired']
) {
  return data.filter((row) => {
    return validMnoStatuses.includes(mnos.find(({ mno }) => mno === row.mno)?.status);
  });
}

export const billingBaseQuery = async ({ url, method, body, headers }, api, extraOptions) => {
  try {
    let result;
    if (method === 'GET') {
      result = await API.get('billing-api', url, { headers });
    } else if (method === 'PUT') {
      result = await API.put('billing-api', url, { body, headers });
    } else if (method === 'POST') {
      result = await API.post('billing-api', url, { body, headers });
    } else if (method === 'DELETE') {
      result = await API.del('billing-api', url, { headers });
    } else {
      throw new Error(`Unsupported method ${method}`);
    }
    return { data: result };
  } catch (error) {
    return {
      error: {
        status: error.response?.status || error.status,
        data: error.response?.data || error.message,
      },
    };
  }
};
