import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const mnosAdapter = createEntityAdapter({});

export const mnosSlice = createSlice({
  name: 'billing/mno',
  initialState: mnosAdapter.getInitialState({
    loading: false,
    mnoDialog: {
      type: 'new',
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    openMnoDialog: (state, action) => {
      const { actionType, actionData } = action.payload;
      state.mnoDialog = {
        type: actionType,
        props: {
          open: true,
        },
        data: actionData,
      };
    },
    closeMnoDialog: (state, action) => {
      state.mnoDialog = {
        type: 'new',
        props: {
          open: false,
        },
        data: null,
      };
    },
  },
});

export const { openMnoDialog, closeMnoDialog } = mnosSlice.actions;

export default mnosSlice.reducer;
