import FusePageCarded from '@fuse/core/FusePageCarded';
import withReducer from 'app/store/withReducer';
import { styled } from '@mui/material/styles';
import CustomDataGrid from 'app/shared-components/CustomDataGrid';
import { useDispatch, useSelector } from 'react-redux';
import reducer from '../store';
import { openNewCorrectionDialog } from '../store/correctionsSlice';
import CorrectionDialog from './CorrectionDialog';
import FuseLoading from '../../../../@fuse/core/FuseLoading';
import PageHeader from '../../../shared-components/PageHeader';
import BillingSpinner from '../BillingSpinner';
import { isUserBillingAdmin } from '../../../services/permissionUtil';
import CorrectionAction from './CorrectionAction';
import { useGetMnosQuery } from '../store/mnosApi';
import {
  useGetCorrectionsQuery,
} from '../store/correctionsApi';

const Root = styled(FusePageCarded)(({ theme }) => ({
  '& .FusePageCarded-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 136,
      height: 136,
    },
  },
  '& .FusePageCarded-content': {
    display: 'flex',
  },
  '& .FusePageCarded-contentCard': {
    overflow: 'hidden',
  },
}));

const TWO_DAYS_MS = 2 * 24 * 60 * 60 * 1000;
const columns = [
  { field: 'id', headerName: 'ID', width: 50 },
  { field: 'mno', headerName: 'MNO', flex: 1 },
  { field: 'date', headerName: 'Date', flex: 1 },
  { field: 'dateEntered', headerName: 'Date Entered', flex: 1 },
  { field: 'user', headerName: 'User', flex: 1 },
  { field: 'note', headerName: 'Note', flex: 1 },
  { field: 'platformName', headerName: 'Platform', flex: 1 },
  { field: 'billingAccountName', headerName: 'Billing Account', flex: 1 },
  { field: 'contractName', headerName: 'Contract', flex: 1 },
  { field: 'productCode', headerName: 'Product', flex: 1 },
  { field: 'customerVisibleDescription', headerName: 'Product Details', flex: 1 },
  { field: 'contentProviderId', headerName: 'Content Provider ID', flex: 1 },
  { field: 'subscriberAmount', headerName: 'Subscriber Amount', flex: 1 },
  { field: 'subscriberAmountCurrency', headerName: 'Subscriber Currency', flex: 1 },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Action',
    flex: 1,
    renderCell: (cellValues) => {
      return (
        <CorrectionAction
          cellValues={cellValues}
          deleteEnabled={
            new Date(cellValues.row.dateEntered) >= new Date(new Date().getTime() - TWO_DAYS_MS)
          }
        />
      );
    },
  },
];

function Corrections() {
  const dispatch = useDispatch();
  const { data: mnos = [] } = useGetMnosQuery();

  const {
    data: corrections = [],
    isLoading: correctionsLoading,
    isFetching: correctionsIsFetching,
    refetch,
  } = useGetCorrectionsQuery(true);

  const user = useSelector(({ auth }) => auth.user);
  const isBillingAdmin = isUserBillingAdmin(user);

  return (
    <>
      <Root
        header={
          <PageHeader
            iconName="edit"
            title="Billing Corrections"
            buttonName="New Correction"
            noHeaderButton={!isBillingAdmin}
            onButtonClick={() => dispatch(openNewCorrectionDialog())}
          />
        }
        content={
          correctionsLoading || correctionsIsFetching ? (
            <FuseLoading />
          ) : (
            <CustomDataGrid
              name="corrections"
              rows={corrections}
              columns={columns}
              onRefresh={() => {
                refetch();
              }}
              density="compact"
            />
          )
        }
        innerScroll
      />
      <CorrectionDialog />
      <BillingSpinner />
    </>
  );
}

export default withReducer('billing', reducer)(Corrections);
