import FusePageCarded from '@fuse/core/FusePageCarded';
import withReducer from 'app/store/withReducer';
import { styled, useTheme } from '@mui/material/styles';
import CustomDataGrid from 'app/shared-components/CustomDataGrid';
import { useDispatch, useSelector } from 'react-redux';
import { forwardRef, useEffect, useMemo, useState, useCallback } from 'react';
import { Button, Chip, Snackbar, Tooltip } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { useSearchParams } from 'react-router-dom';
import { InfoOutlined } from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';
import { showMessage } from 'app/store/fuse/messageSlice';
import convertMuiSortModelToGraphqlSortModel from 'app/services/sortingUtil';
import { isEmpty } from 'lodash';
import { getOrders, selectOrders } from '../store/ordersSlice';
import reducer from '../store';
import OrdersAction from './OrdersAction';
import { getOrderStatus, getOrderStatusByName } from '../order/orderHelper';
import {
  getTargetedPercentage,
  getSendingStartDateTime,
  getLatestSendDate,
  getRejectionReason,
  getTargetedCount,
} from '../executionStatuses/executionStatusHelper';
import PageHeader from '../../../shared-components/PageHeader';
import ProgressBar from '../../../shared-components/ProgressBar';
import { OrderStatus } from '../../../services/constants';
import _ from '../../../../@lodash';
import {
  isSingleMno,
  isSingleBillingAccount,
  isSingleTemplate,
  getMnoList,
  isUserHasFullOrderAccess,
} from '../../../services/permissionUtil';
import { toMnoLocalTimeStr } from '../../../services/dateUtil';
import { openDialog } from '../../../store/fuse/dialogSlice';
import UserNotificationDialog from '../notifications/UserNotificationDialog';
import { SCHEDULE, SUBMIT } from '../../admin/permission/constants';
import * as ordersConstants from './ordersConstants';
import getTemplateName, { getAccountName } from '../../../services/orderUtil';
import filterUtil from '../../../services/filterUtil';
import {
  getLinkOperator,
  getNumberFilterOperators,
  getDateFilterOperators,
  getStringFilterOperators,
  getPermissionFilterOperators,
  getEntitiesByPermissions,
} from '../../../services/dataGridUtil';
import OrderPresetFilters from './components/OrderPresetFilters';
import { useGetAccountsQuery } from '../../billing-admin/store/accountsApi';
import { useGetTemplatesQuery } from '../../admin/store/templatesApi';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Root = styled(FusePageCarded)(({ theme }) => ({
  '& .FusePageCarded-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 136,
      height: 136,
    },
  },
  '& .FusePageCarded-content': {
    display: 'flex',
  },
  '& .FusePageCarded-contentCard': {
    overflow: 'hidden',
  },
}));

function filterByMnoResult(filterModel, mno) {
  const mnoFilter = filterModel.items.find((item) => item.columnField === 'mno');
  if (!mnoFilter) return true;
  if (mnoFilter.operatorValue === 'is') {
    return mno === mnoFilter.value;
  }
  if (mnoFilter.operatorValue === 'not') {
    return mno !== mnoFilter.value;
  }
  if (mnoFilter.operatorValue === 'isAnyOf') {
    return mnoFilter.value?.includes(mno);
  }
  return true;
}

const columns = (
  singleMno,
  singleAccount,
  singleTemplate,
  orders,
  templates,
  permissions,
  accounts,
  theme,
  filterModel
) => [
  {
    field: 'state',
    valueGetter: (params) => getOrderStatus(params.row).name,
    renderCell: (params) => {
      return (
        <Chip
          icon={
            getRejectionReason(params.row) && (
              <Tooltip title={getRejectionReason(params.row)}>
                <InfoOutlined style={{ color: 'white' }} />
              </Tooltip>
            )
          }
          label={_.startCase(_.lowerCase(getOrderStatus(params.row).name))}
          sx={{
            bgcolor: theme.palette.order_status[_.snakeCase(getOrderStatus(params.row).name)],
            color: theme.palette.common.white,
          }}
          size="small"
        />
      );
    },
    sortComparator: (v1, v2) => getOrderStatusByName(v1).order - getOrderStatusByName(v2).order,
    headerName: 'Status',
    type: 'singleSelect',
    valueOptions: [...new Set(Object.keys(OrderStatus))],
    flex: 0.8,
    sortable: false,
  },
  {
    field: 'id',
    headerName: 'Order ID',
    flex: 0.75,
    hide: true,
    sortable: false,
    filterOperators: getStringFilterOperators(),
  },
  {
    field: 'mno',
    headerName: 'MNO',
    type: 'singleSelect',
    valueFormatter: (params) => {
      return _.upperCase(params.value);
    },
    valueOptions: [...new Set(getMnoList(permissions, false, false))],
    filterOperators: getPermissionFilterOperators(permissions, 'mno'),
    flex: 0.6,
    hide: singleMno,
  },
  {
    field: 'billingAccountId',
    headerName: 'Billing Account',
    type: 'singleSelect',
    valueFormatter: (params) => getAccountName(accounts, params.value),
    valueOptions: [
      ...new Map(
        getEntitiesByPermissions(permissions, accounts, 'billingAccount').map((account) => [
          account.id,
          {
            value: account.id,
            label: `${account.name} (${account.mno})`,
            mno: account.mno,
          },
        ])
      ).values(),
    ]
      .filter((acc) => filterByMnoResult(filterModel, acc.mno))
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())),
    filterOperators: getPermissionFilterOperators(permissions, 'billingAccount'),
    flex: 1,
    filterable: true,
    sortable: false,
    hide: singleAccount,
  },
  {
    field: 'campaignName',
    headerName: 'Campaign Name',
    valueGetter: (params) => params.row?.name || params.row?.definition?.name,
    filterOperators: getStringFilterOperators(),
    flex: 1,
    filterable: true,
    sortable: false,
  },
  {
    field: 'product',
    headerName: 'Product',
    valueGetter: (params) => params.row?.product,
    filterOperators: getPermissionFilterOperators(
      permissions,
      filterUtil.FILTER_MAP.product.permissionName
    ),
    valueOptions: filterUtil.getFilteredProductsByPermission(permissions),
    flex: 1,
    filterable: true,
    hide: true,
  },
  {
    field: 'templateId',
    headerName: 'Template Name',
    valueFormatter: (params) => getTemplateName(templates, params.value),
    type: 'singleSelect',
    valueOptions: [
      ...new Map(
        getEntitiesByPermissions(permissions, templates, 'campaignTemplate').map((template) => [
          template.id,
          {
            value: template.id,
            label: template.name,
            mno: template.mno,
          },
        ])
      ).values(),
    ]
      .filter((template) => filterByMnoResult(filterModel, template.mno))
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())),
    filterOperators: getPermissionFilterOperators(permissions, 'campaignTemplate'),
    flex: 1,
    hide: singleTemplate,
    sortable: false,
  },
  {
    field: 'validFrom',
    headerName: 'Validity Start',
    type: 'date',
    valueGetter: (params) => `${params.row.validFrom} ${params.row.startTime || ''}`,
    filterOperators: getDateFilterOperators(),
    flex: 1,
  },
  {
    field: 'validTo',
    headerName: 'Validity End',
    type: 'date',
    flex: 1,
    valueGetter: (params) => `${params.row.validTo} ${params.row.endTime || ''}`,
    filterOperators: getDateFilterOperators(),
    hide: true,
  },
  {
    field: 'sendingStart',
    headerName: 'Sending Start',
    type: 'date',
    valueGetter: (params) => getSendingStartDateTime(params.row),
    valueFormatter: (params) => params.value || 'N/A',
    filterable: false,
    sortable: false,
    flex: 1.1,
  },
  {
    field: 'latestSendDate',
    headerName: 'Latest Send Date',
    valueGetter: (params) => getLatestSendDate(params.row),
    valueFormatter: (params) => params.value || 'N/A',
    type: 'date',
    filterable: false,
    sortable: false,
    flex: 1.1,
  },
  {
    field: 'bidPrice',
    headerName: 'CPM Price of the Order',
    type: 'number',
    hide: true,
    flex: 1,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: 'targetRequested',
    headerName: 'Ordered',
    type: 'number',
    flex: 1,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: 'targetable',
    headerName: 'Targetable',
    type: 'number',
    valueGetter: (params) => params.row.targetable || 'N/A',
    flex: 1,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: 'targeted',
    headerName: 'Targeted',
    type: 'number',
    valueGetter: (params) => getTargetedCount(params.row),
    filterable: false,
    flex: 1,
  },
  {
    field: 'targetedPercentage',
    valueGetter: (params) => getTargetedPercentage(params.row),
    renderCell: (params) => {
      return <ProgressBar value={getTargetedPercentage(params.row)} />;
    },
    headerName: '% Targeted',
    headerAlign: 'center',
    filterable: false,
    sortable: false,
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    type: 'date',
    hide: true,
    valueGetter: (params) => toMnoLocalTimeStr(params.row.createdAt, params.row.mno),
    filterOperators: getDateFilterOperators(),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    type: 'date',
    valueGetter: (params) => toMnoLocalTimeStr(params.row.updatedAt, params.row.mno),
    filterOperators: getDateFilterOperators(),
  },
  {
    field: 'ownerId',
    headerName: 'Created By',
    valueGetter: (params) => params.row?.ownerId,
    filterOperators: getStringFilterOperators(),
    flex: 1,
    sortable: false,
    hide: true,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    flex: 1.5,
    renderCell: (cellValues) => {
      return (
        <OrdersAction
          cellValues={cellValues}
          hasExecutionStatus={
            cellValues.row.executionStatuses?.items?.filter((status) => !status.rejected).length > 0
          }
        />
      );
    },
  },
];

function Orders(props) {
  const theme = useTheme();
  const { permissions } = useSelector(({ auth }) => auth.user);
  const { filter, sorting } = useSelector(({ dataGrid }) => dataGrid);
  const dispatch = useDispatch();

  const singleMno = useMemo(() => isSingleMno(permissions), [permissions]);
  const singleAccount = useMemo(() => isSingleBillingAccount(permissions), [permissions]);
  const singleTemplate = useMemo(() => isSingleTemplate(permissions), [permissions]);

  const { data: templates = [] } = useGetTemplatesQuery();
  const { data: accounts = [] } = useGetAccountsQuery();
  const orders = useSelector(selectOrders);

  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setOpen] = useState(false);
  const [actionRequiredOrderType, setActionRequiredOrderType] = useState(null);

  const page = searchParams.get('page') ? parseInt(searchParams.get('page'), 10) : 0;

  const setPage = (pageNumber) => {
    searchParams.set('page', pageNumber);
    setSearchParams(searchParams, { replace: true });
  };

  const highlighted = searchParams.get('highlighted');

  const { loading, tableFilterIndex, total, tokenMap } = useSelector(
    ({ journey }) => journey.orders
  );

  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [sortModel, setSortModel] = useState([]);

  const handlePageChange = (pageNumber, apiRef) => {
    if (!apiRef.api.windowRef || !apiRef.api.windowRef.current) return;
    if (page !== pageNumber) {
      setPage(pageNumber);
    }
  };
  const handleFilterChange = useCallback(
    (model) => {
      validateProductFilterWithMno(model);
      handleIfModelChange(filterModel, model, setFilterModel);
    },
    [filterModel, permissions]
  );

  const handleSortModelChange = useCallback(
    (model) => {
      if (isEmpty(model)) {
        const { sort } = sortModel[0];
        const newModel = structuredClone(sortModel);
        newModel[0].sort = sort === 'asc' ? 'desc' : 'asc';
        handleIfModelChange(sortModel, newModel, setSortModel);
      } else {
        handleIfModelChange(sortModel, model, setSortModel);
      }
    },
    [sortModel]
  );

  const handleIfModelChange = (oldModel, newModel, setModel) => {
    const isEqual = _.isEqual(oldModel, newModel);
    if (!isEqual) {
      setPage(0);
      setModel(newModel);
    }
  };

  const dataReady = useMemo(() => {
    return (
      permissions.length > 0 && accounts.length > 0 && templates.length > 0 && !_.isEmpty(sortModel)
    );
  }, [permissions.length, accounts.length, templates.length, sortModel]);

  const isDefaultFilterDataReady = useMemo(() => {
    return permissions.length > 0 && accounts.length > 0 && templates.length > 0;
  }, [permissions.length, accounts.length, templates.length]);

  useEffect(() => {
    if (!tokenMap[page]) {
      setPage(0);
    }
  }, [tokenMap]);

  useEffect(() => {
    if (!dataReady) return;

    const shouldKeep = searchParams.get('keep');
    if (shouldKeep) {
      searchParams.delete('keep');
      setSearchParams(searchParams);
    }

    const parameters = {
      filter: filterUtil.getModelForQuery(filterModel, accounts),
      sorting: convertMuiSortModelToGraphqlSortModel(sortModel),
      pageNumber: page,
      keepNewOrder: !!shouldKeep,
    };

    if (tokenMap[page]) {
      parameters.nextToken = tokenMap[page];
    }

    dispatch(getOrders(parameters));
  }, [filterModel, sortModel, dataReady, page]);

  useEffect(() => {
    if (isEmpty(sortModel)) {
      setSortModel(sorting.orders ?? ordersConstants.DEFAULT_SORTING);
    }
  }, []);

  useEffect(() => {
    if (isEmpty(filterModel.items) && isDefaultFilterDataReady) {
      setFilterModel(
        filter.orders ?? {
          items: filterUtil.convertPermissionsToMuiFilter(permissions, accounts, templates),
        }
      );
    }
  }, [isDefaultFilterDataReady]);

  useEffect(() => {
    if (!orders || orders.length === 0 || !dataReady) {
      return;
    }

    const approvePermission = permissions.some((permission) => permission.permission === 'Approve');
    const submitPermission = permissions.some((permission) => permission.permission === 'Submit');

    if (
      approvePermission &&
      orders.some((order) => order.status === OrderStatus.WAITING_FOR_APPROVAL.name)
    ) {
      setOpen(true);
      setActionRequiredOrderType(OrderStatus.WAITING_FOR_APPROVAL.name);
    } else if (
      submitPermission &&
      orders.some((order) => order.status === OrderStatus.REJECTED.name)
    ) {
      setOpen(true);
      setActionRequiredOrderType(OrderStatus.REJECTED.name);
    }
  }, [dataReady, orders, permissions]);

  useEffect(() => {
    if (searchParams?.get('notificationSettings') === 'true') {
      dispatch(
        openDialog({
          children: <UserNotificationDialog />,
        })
      );
    }
  }, [searchParams, dispatch]);

  const FilterButtonGroup = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          delimiter: ';',
          fileName: `Orders_${new Date().toISOString().split('T')[0]}`,
        }}
        printOptions={{ disableToolbarButton: true }}
      />
      <OrderPresetFilters
        handleFilterChange={handleFilterChange}
        handleSortModelChange={handleSortModelChange}
      />
    </GridToolbarContainer>
  );

  if (
    searchParams &&
    searchParams.get('notificationSettings') &&
    searchParams.get('notificationSettings') === 'true'
  ) {
    dispatch(
      openDialog({
        children: <UserNotificationDialog />,
      })
    );
  }

  function handleStateChange() {
    return (state) => {
      const visibleRows = state.filter.visibleRowsLookup;
      const visibleItems = [];
      if (
        state.filter.filterModel &&
        state.filter.filterModel.items &&
        state.filter.filterModel.items.length
      ) {
        for (const [id, value] of Object.entries(visibleRows)) {
          if (value === true) {
            visibleItems.push(id);
          }
        }
      }
    };
  }

  function validateProductFilterWithMno(model) {
    if (
      !model.items.some((item) => item.columnField === 'mno' && item.value) &&
      model.items.some((item) => item.columnField === 'product' && item.value) &&
      isUserHasFullOrderAccess(permissions)
    ) {
      dispatch(
        showMessage({
          message: 'Please select an MNO to filter by product',
          variant: 'warning',
        })
      );
    }
  }

  function newOrderDisabled() {
    return !permissions.filter(
      (permission) => permission.permission === SCHEDULE || permission.permission === SUBMIT
    ).length;
  }

  function handleClose() {
    setOpen(false);
  }

  function setWaitingForApprovalFilter() {
    if (actionRequiredOrderType) {
      const model = {
        items: filterUtil.convertPermissionsToMuiFilter(permissions, accounts, templates),
      };
      model.items.push({
        columnField: 'state',
        value: actionRequiredOrderType,
        operatorValue: 'is',
        id: '60000',
      });
      handleFilterChange(model);
      handleClose();
    }
  }

  return (
    <Root
      header={
        <>
          <PageHeader
            iconName="list"
            disabled={newOrderDisabled()}
            title="Orders"
            buttonName="New Order"
            buttonLink="/orders/new"
          />
          <Snackbar
            open={isOpen}
            onClose={handleClose}
            sx={{ height: '25%' }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              severity="info"
              action={
                <Button onClick={setWaitingForApprovalFilter} color="primary" size="small">
                  Show
                </Button>
              }
            >
              There are Orders that require your action.
            </Alert>
          </Snackbar>
        </>
      }
      content={
        <CustomDataGrid
          key="orders-grid-key"
          name={ordersConstants.DATA_GRID_NAME}
          defaultSorting={ordersConstants.DEFAULT_SORTING}
          rows={orders}
          columns={columns(
            singleMno,
            singleAccount,
            singleTemplate,
            orders,
            templates,
            permissions,
            accounts,
            theme,
            filterModel
          )}
          filterModel={filterModel}
          customToolbar={FilterButtonGroup}
          componentsProps={{
            filterPanel: {
              linkOperators: getLinkOperator(permissions),
            },
          }}
          density="compact"
          pageSize={100}
          rowsPerPageOptions={[100]}
          rowCount={total}
          paginationMode="server"
          onPageChange={handlePageChange}
          page={page}
          filterMode="server"
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          loading={loading}
          onStateChange={handleStateChange()}
          onFilterChange={handleFilterChange}
          hideFooterPagination={loading}
          getRowClassName={(params) => {
            if (highlighted && params.row.id === highlighted) {
              return 'bg-blue-100';
            }
            return '';
          }}
        />
      }
      innerScroll
    />
  );
}

export default withReducer('journey', reducer)(Orders);
