import filterUtil from 'app/services/filterUtil';
import { OrderStatus } from 'app/services/constants';
import { Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { PublishedWithChanges, Today, Update } from '@mui/icons-material';
import HistoryIcon from '@mui/icons-material/History';
import { useDispatch, useSelector } from 'react-redux';
import { saveFilter } from 'app/store/customDataGridSlice';
import * as ordersConstants from '../ordersConstants';
import { useGetAccountsQuery } from '../../../billing-admin/store/accountsApi';
import { useGetTemplatesQuery } from '../../../admin/store/templatesApi';

export default function OrderPresetFilters({ handleFilterChange, handleSortModelChange }) {
  const dispatch = useDispatch();

  const { permissions } = useSelector(({ auth }) => auth.user);
  const { data: accounts } = useGetAccountsQuery();
  const { data: templates = [] } = useGetTemplatesQuery();
  const { loading, tableFilterIndex } = useSelector(({ journey }) => journey.orders);
  const { filter } = useSelector(({ dataGrid }) => dataGrid);

  function sortByUpdatedAtDesc() {
    handleSortModelChange([
      {
        field: 'updatedAt',
        sort: 'desc',
      },
    ]);
  }

  const clearStatusFilter = () => {
    if (filter[ordersConstants.DATA_GRID_NAME]) {
      const { items } = filter[ordersConstants.DATA_GRID_NAME];
      if (items && items.length && items.filter((i) => i.columnField === 'state').length) {
        dispatch(
          saveFilter({
            name: 'orders',
            model: { items: items.filter((i) => i.columnField !== 'state') },
          })
        );
      }
    }
  };

  function handleResetFilters(index, keepStatusFilter = false) {
    const defaultFilterModel = {
      items: filterUtil.convertPermissionsToMuiFilter(permissions, accounts, templates),
    };
    const defaultSortModel = ordersConstants.DEFAULT_SORTING;
    handleFilterChange(defaultFilterModel);
    handleSortModelChange(defaultSortModel);
    if (!keepStatusFilter) clearStatusFilter();
  }

  function handleSelectTodaysOrders() {
    const todaysOrderFilters = {
      items: [
        ...filterUtil.convertPermissionsToMuiFilter(permissions, accounts, templates),
        {
          columnField: 'updatedAt',
          value: new Date().toISOString().split('T')[0],
          operatorValue: 'is',
          id: '10000',
        },
        {
          columnField: 'validFrom',
          value: new Date().toISOString().split('T')[0],
          operatorValue: 'onOrBefore',
          id: '10001',
        },
      ],
    };
    handleFilterChange(todaysOrderFilters);
    sortByUpdatedAtDesc();
  }

  function handleSelectTomorrowsOrders() {
    const tomorrowsOrderFilters = {
      items: [
        ...filterUtil.convertPermissionsToMuiFilter(permissions, accounts, templates),
        {
          columnField: 'validFrom',
          value: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0],
          operatorValue: 'is',
          id: '10000',
        },
        {
          columnField: 'state',
          operatorValue: 'isAnyOf',
          id: '10001',
          value: [OrderStatus.WAITING_FOR_APPROVAL.name, OrderStatus.SCHEDULED.name],
        },
      ],
    };
    handleSortModelChange(ordersConstants.DEFAULT_SORTING);
    handleFilterChange(tomorrowsOrderFilters);
  }

  function handleSelectLast3DaysOrders() {
    const last3DaysOrderFilters = {
      items: [
        ...filterUtil.convertPermissionsToMuiFilter(permissions, accounts, templates),
        {
          columnField: 'updatedAt',
          value: new Date(new Date().setDate(new Date().getDate() - 2)).toISOString().split('T')[0],
          operatorValue: 'onOrAfter',
          id: '10000',
        },
        {
          columnField: 'validFrom',
          value: new Date().toISOString().split('T')[0],
          operatorValue: 'onOrBefore',
          id: '10001',
        },
      ],
    };
    handleFilterChange(last3DaysOrderFilters);
    sortByUpdatedAtDesc();
  }

  function handleSelectActiveOrders() {
    const activeOrderFilters = {
      items: [
        ...filterUtil.convertPermissionsToMuiFilter(permissions, accounts, templates),
        {
          columnField: 'state',
          operatorValue: 'is',
          id: '10000',
          value: OrderStatus.ACTIVE.name,
        },
      ],
    };
    handleFilterChange(activeOrderFilters);
    sortByUpdatedAtDesc();
  }

  return (
    <>
      <Button
        size="small"
        selected={tableFilterIndex}
        onClick={handleResetFilters}
        disabled={loading}
        startIcon={<RefreshIcon />}
      >
        Reset
      </Button>
      <Button
        size="small"
        onClick={handleSelectActiveOrders}
        disabled={loading}
        startIcon={<PublishedWithChanges />}
      >
        Active orders
      </Button>
      <Button
        size="small"
        onClick={handleSelectTodaysOrders}
        disabled={loading}
        startIcon={<Today />}
      >
        Today's orders
      </Button>
      <Button
        size="small"
        onClick={handleSelectLast3DaysOrders}
        disabled={loading}
        startIcon={<HistoryIcon />}
      >
        Last 3 day's orders
      </Button>
      <Button
        size="small"
        onClick={handleSelectTomorrowsOrders}
        disabled={loading}
        startIcon={<Update />}
      >
        Tomorrow's orders
      </Button>
    </>
  );
}
