import i18n from 'src/i18n';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';
import { authRoles, roleNames } from '../auth/authRoles';

i18n.addResourceBundle('en', 'navigation', en);
i18n.addResourceBundle('tr', 'navigation', tr);
i18n.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
  {
    id: 'applications',
    title: 'Applications',
    translate: 'APPLICATIONS',
    type: 'group',
    icon: 'apps',
    children: [
      {
        id: 'orders',
        title: 'Orders',
        type: 'item',
        url: '/orders',
        icon: 'list',
        auth: authRoles.userWithOrderPermission,
      },
      {
        id: 'reports',
        title: 'Reports',
        type: 'collapse',
        icon: 'analytics',
        auth: [
          roleNames.USER_WITH_SEARCH_MSISDN_ACTIVITY,
          roleNames.USER_WITH_ORDER_PERMISSION,
          roleNames.USER_WITH_VIEW_PROVISIONED_SUBSCRIBERS,
        ],
        children: [
          {
            id: 'subscriber-activity',
            title: 'Subscriber Activity',
            type: 'item',
            url: '/reports/subscriber-activity',
            icon: 'list',
            auth: authRoles.userWithSearchMSISDNActivity,
          },
          {
            id: 'performance-report',
            title: 'Performance',
            type: 'item',
            url: '/reports/performance',
            icon: 'poll',
            auth: authRoles.userWithOrderPermission,
          },
          {
            id: 'provisioned-subscribers-report',
            title: 'Provisioned Subscribers',
            type: 'item',
            url: '/reports/provisioned-subscribers',
            icon: 'trending_up',
            auth: authRoles.userWithViewProvisionedSubscribers,
          },
          {
            id: 'contact-policy-report',
            title: 'Contact Policy',
            type: 'item',
            url: '/reports/contact-policy',
            icon: 'policy',
            auth: authRoles.userWithContactPolicyAccess,
          },
        ],
      },
      {
        id: 'billing',
        title: 'Billing',
        type: 'collapse',
        icon: 'attach_money',
        auth: authRoles.userWithBillingPermission,
        children: [
          {
            id: 'billingSummary',
            title: 'Summary',
            type: 'item',
            url: '/billing/summary',
            icon: 'layers',
          },
          {
            id: 'payment',
            title: 'Payment',
            type: 'item',
            url: '/payment',
            icon: 'payment',
            auth: authRoles.userWithPaymentFunctionality,
          },
          {
            id: 'paymentHistory',
            title: 'Payment History',
            type: 'item',
            url: '/billing/payment-history',
            icon: 'payments',
            auth: authRoles.userWithPaymentFunctionality,
          },
          {
            id: 'accountBalance',
            title: 'Account Balance',
            type: 'item',
            url: '/billing/account-balance',
            icon: 'money',
            auth: authRoles.userWithPaymentFunctionality,
          },
        ],
      },
      {
        id: 'billingAdmin',
        title: 'Billing Administration',
        type: 'collapse',
        icon: 'monetization_on',
        auth: [roleNames.BILLING_ADMIN, roleNames.SELF_ADMIN],
        children: [
          {
            id: 'billingAccounts',
            title: 'Accounts',
            type: 'item',
            url: '/billing-admin/accounts',
            icon: 'account_box',
          },
          {
            id: 'billingCodes',
            title: 'Codes',
            type: 'item',
            url: '/billing-admin/codes',
            icon: 'code',
          },
          {
            id: 'billingCorrections',
            title: 'Corrections',
            type: 'item',
            url: '/billing-admin/corrections',
            icon: 'edit',
          },
          {
            id: 'billingContracts',
            title: 'Contracts',
            type: 'item',
            url: '/billing-admin/contracts',
            icon: 'edit',
          },
          {
            id: 'mno',
            title: 'Mno',
            type: 'item',
            url: '/billing-admin/mno',
            icon: 'phone',
          },
          {
            id: 'billingRefreshDataset',
            title: 'Refresh Dataset',
            type: 'item',
            url: '/billing-admin/refresh-dataset',
            icon: 'layers',
          },
          {
            id: 'paymentEntries',
            title: 'Payment Entry',
            type: 'item',
            url: '/billing-admin/payment-entry',
            icon: 'money',
          },
        ],
      },
      {
        id: 'admin',
        title: 'Admin',
        type: 'collapse',
        icon: 'admin_panel_settings',
        auth: [roleNames.ADMIN, roleNames.SELF_ADMIN],
        children: [
          {
            id: 'permissions',
            title: 'Permissions',
            type: 'item',
            url: '/admin/permissions',
            icon: 'group',
          },
          {
            id: 'userSettings',
            title: 'User Settings',
            type: 'item',
            url: '/admin/user-settings',
            icon: 'settings',
          },
          {
            id: 'mtn-sa-provider-ids',
            title: 'MTN SA - Provider IDs',
            type: 'item',
            url: '/admin/mtn-sa-provider-ids',
            icon: 'list',
          },
        ],
      },
      {
        id: 'techSupport',
        title: 'Technical Support',
        type: 'collapse',
        icon: 'support',
        auth: ['support'],
        children: [
          {
            id: 'system-attributes',
            title: 'System Attributes',
            type: 'item',
            url: '/support/system-attributes',
            icon: 'filter_list',
          },
          {
            id: 'templates',
            title: 'Templates',
            type: 'item',
            url: 'support/templates',
            icon: 'list',
          },
          {
            id: 'journeys',
            title: 'Journeys',
            type: 'item',
            url: 'support/journeys',
            icon: 'code',
          },
          {
            id: 'billingPlatform',
            title: 'Platforms',
            type: 'item',
            url: 'support/platforms',
            icon: 'layers',
          },
          {
            id: 'api-requests',
            title: 'Api Requests',
            type: 'item',
            url: '/support/api-requests',
            icon: 'message',
          },
          {
            id: 'mno-configuration',
            title: 'MNO Configuration',
            type: 'item',
            url: '/support/mno-configuration',
            icon: 'settings',
          },
        ],
      },
    ],
  },
];

export default navigationConfig;
