import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import DialogContent from '@mui/material/DialogContent';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { Grid } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormInputText from 'app/shared-components/FormInputText';
import FormSwitch from 'app/shared-components/FormSwitch';
import Alert from '@mui/material/Alert';
import FormMnoDropdown from 'app/shared-components/FormMnoDropdown';
import _ from '../../../../@lodash';
import {
  useCreateMnoConfigurationMutation,
  useUpdateMnoConfigurationMutation,
} from '../store/mnoConfigurationApi';
import { closeMnoConfigurationDialog } from '../store/mnoConfigurationSlice';

const defaultValues = {
  mno: '',
  cps: '',
  lastActivityDurationInDays: '',
  activationExclusionInDays: '',
  targetingThreshold: '',
  transmitterTps: '',
  msisdnCountryCode: '',
  billingPlatformId: '',
  cutOffDeliveryTime: '',
  dndCriteriaEnabled: false,
  billingForRemindMeLaterEnabled: false,
  optOutAllOptionEnabled: false,
  sundayExecutionDisabled: false,
  priceConstant: 1,
  bidPriceHelperText: 'Price per one thousand messages',
};

export default function MnoConfigurationDialog() {
  const dispatch = useDispatch();
  const { data, open, type } = useSelector(({ support }) => support.mnoConfigurations);
  const [createMnoConfiguration] = useCreateMnoConfigurationMutation();
  const [updateMnoConfiguration] = useUpdateMnoConfigurationMutation();

  const schema = yup.object().shape({
    mno: yup
      .string()
      .required('You must select MNO Name')
      .matches(
        /^[^_]+(_[^_]+)+$/,
        'MNO must include one or more underscores (_) separating valid parts, and it cannot start or end with an underscore.'
      ),
    cps: yup
      .string()
      .notRequired()
      .nullable()
      .test(
        'is-valid-cps',
        'CPS must follow the format MediaX=2d:1,7d:3;BoosterX=2d:1,7d:3',
        (value) => !value || /^(\w+=\d+d:\d+(,\d+d:\d+)*)(;\w+=\d+d:\d+(,\d+d:\d+)*)*$/.test(value)
      ),
    lastActivityDurationInDays: yup
      .string()
      .notRequired()
      .nullable()
      .test(
        'is-valid-last-activity-duration',
        'lastActivityDurationInDays must follow the format MediaX=90,BoosterX=90,BrandX=90',
        (value) => !value || /^\w+=\d+(,\w+=\d+)*$/.test(value)
      ),
    activationExclusionInDays: yup
      .string()
      .notRequired()
      .nullable()
      .test(
        'is-valid-activation-exclusion',
        'activationExclusionInDays must follow the format MediaX=90,BoosterX=90,BrandX=90',
        (value) => !value || /^\w+=\d+(,\w+=\d+)*$/.test(value)
      ),
    targetingThreshold: yup
      .string()
      .notRequired()
      .nullable()
      .test(
        'is-valid-targeting-threshold',
        'targetingThreshold must follow the format MediaX=5000000,BoosterX=5000000,BrandX=5000000',
        (value) => !value || /^\w+=\d+(,\w+=\d+)*$/.test(value)
      ),
    transmitterTps: yup
      .string()
      .required('You must define transmitter TPS')
      .matches(/^\d+$/, 'Transmitter TPS must be a numeric value'),
    msisdnCountryCode: yup
      .string()
      .required('You must define MSISDN Country Code')
      .matches(/^\d+$/, 'Country Code must be a numeric value'),
    billingPlatformId: yup.string().required('You must define Billing Platform ID'),
    transmitterStartTime: yup
      .string()
      .required('You must define transmitter start time')
      .matches(
        /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
        'Transmitter Start Time must follow the format HH:MM and must be a valid hour.'
      ),
    transmitterEndTime: yup
      .string()
      .required('You must define transmitter end time')
      .matches(
        /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
        'Transmitter End Time must follow the format HH:MM and must be a valid hour.'
      ),
    cutOffDeliveryTime: yup
      .string()
      .required('You must define cut off delivery time')
      .matches(
        /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
        'Cut Off Delivery Time must follow the format HH:MM and must be a valid hour.'
      ),
    billingForRemindMeLaterEnabled: yup
      .boolean()
      .required('Billing for Remind Me Later must be enabled or disabled')
      .typeError('Billing for Remind Me Later must be a boolean value'),
    dndCriteriaEnabled: yup
      .boolean()
      .required('DND Criteria must be enabled or disabled')
      .typeError('DND Criteria must be a boolean value'),
    optOutAllOptionEnabled: yup
      .boolean()
      .required('Opt-Out All Option must be enabled or disabled')
      .typeError('Opt-Out All Option must be a boolean value'),
    minimumBidPrice: yup
      .mixed()
      .test('is-numeric-or-null', 'Minimum Bid Price must be numeric', (value) => {
        return value === '' || !Number.isNaN(parseFloat(value));
      }),
    maximumBidPrice: yup
      .mixed()
      .test('is-numeric-or-null', 'Minimum Bid Price must be numeric', (value) => {
        return value === '' || !Number.isNaN(parseFloat(value));
      }),
    sundayExecutionDisabled: yup
      .boolean()
      .required('Sunday Execution Disabled Option is required')
      .typeError('Sunday Execution Disabled Option must be a boolean value'),
    priceConstant: yup.number().required('You must define price constant.'),
    bidPriceHelperText: yup.string().required('Bid price helper text is required'),
  });

  const { control, reset, handleSubmit, formState, getValues } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const initDialog = useCallback(() => {
    reset({ ...defaultValues, ...data });
  }, [data, reset]);

  useEffect(() => {
    if (open) {
      initDialog();
    }
  }, [open, initDialog]);

  function onSubmit(payload) {
    console.log(payload, type);
    if (type === 'edit') {
      updateMnoConfiguration(payload);
    } else {
      createMnoConfiguration(payload);
    }
    onDialogClose();
  }
  function onDialogClose() {
    dispatch(closeMnoConfigurationDialog());
  }
  return (
    <Dialog open={open} fullWidth onClose={onDialogClose} onSubmit={handleSubmit(onSubmit)}>
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            {_.capitalize(type)} MNO Configuration
          </Typography>
        </Toolbar>
      </AppBar>
      <form noValidate className="flex flex-col md:overflow-hidden">
        <DialogContent>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item>
              <FormMnoDropdown
                required
                variant="outlined"
                fullWidth
                control={control}
                name="mno"
                placeholder="MNO"
                label="MNO"
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                name="cps"
                label="Contact Policy Configuration"
                placeholder="Contact Policy Configuration"
                helperText={errors.cps?.message || 'Format: MediaX=2d:1,7d:3;BoosterX=2d:1,7d:3'}
              />
            </Grid>
            {dirtyFields.cps && type === 'edit' && (
              <Alert severity="warning">
                Adjustments to the Contact Policy will affect available inventory and may adversely impact orders’ key performance indicators (reach rate/success rate).
              </Alert>
            )}

            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                name="lastActivityDurationInDays"
                label="Target Subscribers Active within Last N Days"
                placeholder="Target Subscribers Active within Last N Days"
                helperText={
                  errors.lastActivityDurationInDays?.message ||
                  'Format: MediaX=90,BoosterX=90,BrandX=90'
                }
              />
            </Grid>
            {dirtyFields.lastActivityDurationInDays && type === 'edit' && (
              <Alert severity="warning">
                Adjustments to the Last Activity Duration will affect available inventory and may adversely impact orders’ key performance indicators (reach rate/success rate).
              </Alert>
            )}
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                name="activationExclusionInDays"
                label="Do not Target Subscribers Activated within Last N Days"
                placeholder="Do not Target Subscribers Activated within Last N Days"
                helperText={
                  errors.activationExclusionInDays?.message ||
                  'Format: MediaX=90,BoosterX=90,BrandX=90'
                }
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                name="targetingThreshold"
                label="Daily Targeting Limit"
                placeholder="Daily Targeting Limit"
                helperText={
                  errors.targetingThreshold?.message ||
                  'Format: MediaX=5000000,BoosterX=5000000,BrandX=5000000'
                }
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                name="transmitterTps"
                label="Transmitter TPS"
                placeholder="Transmitter TPS"
                helperText={errors.transmitterTps?.message || 'Must be a numeric value'}
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                name="msisdnCountryCode"
                label="MSISDN Country Code"
                placeholder="MSISDN Country Code"
                helperText={errors.msisdnCountryCode?.message || 'Must be numeric value'}
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                name="billingPlatformId"
                label="Billing Platform ID"
                placeholder="Billing Platform ID"
                helperText={errors.billingPlatformId?.message}
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                name="transmitterStartTime"
                label="Targeting Start Time (MNO Timezone)"
                placeholder="Targeting Start Time (MNO Timezone)"
                helperText={errors.transmitterStartTime?.message || 'Format: HH:MM'}
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                label="Targeting End Time (MNO Timezone)"
                name="transmitterEndTime"
                placeholder="Targeting End Time (MNO Timezone)"
                helperText={errors.transmitterEndTime?.message || 'Format: HH:MM'}
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                label="Stop All Journey Executions After (MNO Timezone)"
                name="cutOffDeliveryTime"
                placeholder="Stop All Journey Executions After (MNO Timezone)"
                helperText={errors.cutOffDeliveryTime?.message || 'Format: HH:MM'}
              />
            </Grid>
            <Grid item>
              <FormSwitch
                variant="outlined"
                fullWidth
                control={control}
                label="Billing For Remind Me Later Enabled. It should only be used at MTN SA"
                name="billingForRemindMeLaterEnabled"
                placeholder="Remind Me Later"
                helperText={errors.billingForRemindMeLaterEnabled?.message}
              />
            </Grid>
            <Grid item>
              <FormSwitch
                variant="outlined"
                fullWidth
                control={control}
                label="DND Criteria Enabled"
                name="dndCriteriaEnabled"
                placeholder="DND Criteria"
                helperText={errors.dndCriteriaEnabled?.message}
              />
            </Grid>
            <Grid item>
              <FormSwitch
                variant="outlined"
                fullWidth
                control={control}
                label="Opt-Out All Option Enabled"
                name="optOutAllOptionEnabled"
                placeholder="Opt-Out All Option"
                helperText={errors.optOutAllOptionEnabled?.message}
              />
            </Grid>
            <Grid item>
              <FormSwitch
                variant="outlined"
                fullWidth
                control={control}
                label="Sunday Execution Option Disabled"
                name="sundayExecutionDisabled"
                placeholder="Sunday Execution Option Disabled"
                helperText={errors.sundayExecutionDisabled?.message}
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                name="minimumBidPrice"
                label="Minimum CPM Bid Price in Local Currency"
                placeholder="Minimum CPM Bid Price in Local Currency"
                helperText={errors.minimumBidPrice?.message || 'Must be a numeric value'}
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                name="maximumBidPrice"
                label="Maximum CPM Bid Price in Local Currency"
                placeholder="Maximum CPM Bid Price in Local Currency"
                helperText={errors.maximumBidPrice?.message || 'Must be a numeric value'}
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                name="priceConstant"
                label="Order Cost Calculation - Price Constant"
                placeholder="Order Cost Calculation - Price Constant"
                helperText={errors.priceConstant?.message || 'Must be a numeric value'}
              />
            </Grid>
            <Grid item>
              <FormInputText
                variant="outlined"
                fullWidth
                control={control}
                name="bidPriceHelperText"
                label="Order Cost Calculation - Bid Helper Text"
                placeholder="Order Cost Calculation - Bid Helper Text"
                helperText={errors.bidPriceHelperText?.message}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="justify-start px-24 pb-16">
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={_.isEmpty(dirtyFields) || !isValid}
          >
            {type === 'edit' ? 'Save' : 'Add'}
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => dispatch(closeMnoConfigurationDialog())}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
