import { useDispatch } from 'react-redux';
import Root from 'app/shared-components/Root';
import CustomDataGrid from 'app/shared-components/CustomDataGrid';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import FuseLoading from '../../../../@fuse/core/FuseLoading';
import withReducer from '../../../store/withReducer';
import reducer from '../store';
import PageHeader from '../../../shared-components/PageHeader';
import MnoConfigurationDialog from './MnoConfigurationDialog';
import BillingSpinner from '../../billing-admin/BillingSpinner';
import { useGetMnoConfigurationsQuery } from '../store/mnoConfigurationApi';
import { openMnoConfigurationDialog } from '../store/mnoConfigurationSlice';

const columns = [
  { field: 'mno', headerName: 'Mno', flex: 1 },
  { field: 'cps', headerName: 'Contact Policy Configuration', width: 200 },
  {
    field: 'lastActivityDurationInDays',
    headerName: 'Last Activity Duration in Days',
    width: 200,
  },
  {
    field: 'activationExclusionInDays',
    headerName: 'Activation Exclusion in Days',
    width: 200,
  },
  {
    field: 'targetingThreshold',
    headerName: 'Daily Targeting Limit',
    width: 200,
  },
  { field: 'transmitterTps', headerName: 'TPS', flex: 1 },
  {
    field: 'msisdnCountryCode',
    headerName: 'Country Code',
    flex: 1,
    hide: true,
  },
  {
    field: 'billingPlatformId',
    headerName: 'Platform ID',
    flex: 1,
    hide: true,
  },
  {
    field: 'transmitterPeriod',
    headerName: 'Transmitter Period',
    flex: 1,
    valueGetter: (params) => `${params.row.transmitterStartTime}-${params.row.transmitterEndTime}`,
  },
  {
    field: 'cutOffDeliveryTime',
    headerName: 'Cut-off Delivery Time ',
    flex: 1,
  },
  {
    field: 'billingForRemindMeLaterEnabled',
    headerName: 'Billing for Remind Me Later Enabled',
    flex: 1,
    valueFormatter: ({ value }) => (value ? 'Yes' : 'No'),
  },
  {
    field: 'dndCriteriaEnabled',
    headerName: 'DND Enabled',
    flex: 1,
    valueFormatter: ({ value }) => (value ? 'Yes' : 'No'),
    hide: true,
  },
  {
    field: 'optOutAllOptionEnabled',
    headerName: 'Opt out All Option Enabled',
    flex: 1,
    valueFormatter: ({ value }) => (value ? 'Yes' : 'No'),
  },
  {
    field: 'sundayExecutionDisabled',
    headerName: 'Sunday Execution Disabled',
    flex: 1,
    valueFormatter: ({ value }) => (value ? 'Yes' : 'No'),
    hide: true,
  },
  { field: 'minimumBidPrice', headerName: 'Minimum Bid Price', flex: 1, hide: true },
  { field: 'maximumBidPrice', headerName: 'Maximum Bid Price', flex: 1, hide: true },
  { field: 'priceConstant', headerName: 'Price Constant', flex: 1, hide: true },
  { field: 'bidPriceHelperText', headerName: 'Bid Price Helper Text', flex: 1, hide: true },
];

function MnoConfiguration() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const preSelectedMno = searchParams.get('mno');

  const {
    data: mnoConfigurations = [],
    isLoading: mnoConfigurationsLoading,
    refetch,
    isFetching: mnoConfigurationsFetching,
  } = useGetMnoConfigurationsQuery();

  useEffect(() => {
    if (!preSelectedMno || mnoConfigurations.length === 0) return;
    const existingMnoConfiguration = mnoConfigurations.find((r) => r.mno === preSelectedMno);
    if (existingMnoConfiguration) {
      dispatch(
        openMnoConfigurationDialog({
          type: 'edit',
          data: existingMnoConfiguration,
        })
      );
    } else {
      dispatch(openMnoConfigurationDialog({ type: 'new' }));
    }
    searchParams.delete('mno');
    setSearchParams(searchParams);
  }, [preSelectedMno, mnoConfigurations, dispatch, searchParams, setSearchParams]);

  function onRowClick({ row }) {
    dispatch(openMnoConfigurationDialog({ type: 'edit', data: row }));
  }

  const handleRefresh = () => {
    refetch();
  };

  return (
    <>
      <Root
        header={
          <PageHeader
            iconName="settings"
            title="MNO Configuration"
            buttonName="New Configuration"
            onButtonClick={() => dispatch(openMnoConfigurationDialog({ type: 'new' }))}
          />
        }
        content={
          mnoConfigurationsFetching || mnoConfigurationsLoading ? (
            <FuseLoading />
          ) : (
            <CustomDataGrid
              getRowId={(row) => row.mno}
              rows={mnoConfigurations}
              onRowClick={onRowClick}
              columns={columns}
              autoPageSize
              pagination
              onRefresh={handleRefresh}
              disableColumnResize={false}
            />
          )
        }
        innerScroll
      />
      <MnoConfigurationDialog />
      <BillingSpinner />
    </>
  );
}

export default withReducer('support', reducer)(MnoConfiguration);
