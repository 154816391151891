export default function convertMuiSortModelToGraphqlSortModel(model) {
  return model.map((item) => {
    const copyItem = { ...item };
    const direction = copyItem.sort;
    delete copyItem.sort;
    return {
      ...copyItem,
      direction,
    };
  });
}
