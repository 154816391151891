import _ from 'lodash';
import { useSelector } from 'react-redux';
import FormInputDropdown from './FormInputDropdown';
import { getMnoList } from '../services/permissionUtil';
import { useGetMnosQuery } from '../main/billing-admin/store/mnosApi';
import FuseLoading from '@fuse/core/FuseLoading';

function FormMnoDropdown(props) {
  const { permissions } = useSelector(({ auth }) => auth.user);
  const { allmno, noPermissionFiltering, ...remainingProps } = props;
  const { data, isLoading } = useGetMnosQuery();
  const allMnoList = data ? getMnoListNames(data) : [];

  function getMnoListNames(mnos) {
    return (
      mnos &&
      mnos.filter(({ status }) => ['testing', 'deployed'].includes(status)).map(({ mno }) => mno)
    );
  }
  if (isLoading) {
    return <FuseLoading />;
  }

  return (
    <FormInputDropdown
      {...remainingProps}
      options={getMnoList(permissions, allMnoList, allmno === 'true', noPermissionFiltering).map(
        (mno) => ({
          label: _.upperCase(mno),
          value: mno,
        })
      )}
    />
  );
}

export default FormMnoDropdown;
