import { combineReducers } from '@reduxjs/toolkit';
import attributes from './attributesSlice';
import apiRequests from './apiRequestsSlice';
import mnoConfigurations from './mnoConfigurationSlice';

const reducer = combineReducers({
  attributes,
  apiRequests,
  mnoConfigurations,
});

export default reducer;
