import { combineReducers } from '@reduxjs/toolkit';
import platforms from './platformsSlice';
import accounts from './accountsSlice';
import prices from './pricesSlice';
import corrections from './correctionsSlice';
import contracts from './contractsSlice';
import datasetIngestions from './datasetIngestionsSlice';
import mnos from './mnosSlice';

const reducer = combineReducers({
  platforms,
  accounts,
  prices,
  corrections,
  contracts,
  datasetIngestions,
  mnos,
});

export default reducer;
