import FusePageCarded from '@fuse/core/FusePageCarded';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FormInputDropdown from 'app/shared-components/FormInputDropdown';
import { getStyledCurrencyNumber } from 'app/services/orderUtil';
import { getAccountsOfUserWithPaymentFunctionality } from 'app/services/permissionUtil';
import QuickSightFrame from '../../../shared-components/QuickSightFrame';
import PageHeader from '../../../shared-components/PageHeader';
import { ATTRIBUTE_NAME_QS_DASHBOARD_ID_ACCOUNT_BALANCE_REPORT } from '../../../services/constants';
import { convertMnoNamesToIDs, getMergedPermissionAttribute } from '../../../services/reportsUtil';
import FuseLoading from '../../../../@fuse/core/FuseLoading';
import { getPaymentInformation } from '../store/orderSlice';
import { useGetAccountsQuery } from '../../billing-admin/store/accountsApi';
import { useGetMnosQuery } from '../../billing-admin/store/mnosApi';

const schema = yup.object().shape({
  billingAccountIdParameter: yup.string().required('Billing Account id required'),
});

const Root = styled(FusePageCarded)(({ theme }) => ({
  '& .FusePageCarded-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 136,
      height: 136,
    },
  },
  '& .FusePageCarded-content': {
    display: 'flex',
  },
  '& .FusePageCarded-contentCard': {
    overflow: 'hidden',
  },
}));

const defaultValues = {
  billingAccountIdParameter: '',
};

function AccountBalanceReport() {
  const dispatch = useDispatch();
  const { permissions } = useSelector(({ auth }) => auth.user);
  const { data: mnos = [], isLoading: loadingMnos } = useGetMnosQuery();
  const { data: accounts } = useGetAccountsQuery();
  const mnosOfUser = getMergedPermissionAttribute(permissions, 'mno');
  const { accountPaymentInformation, accountPaymentInformationLoading } = useSelector(
    ({ journey }) => journey.order
  );

  const { control, watch, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const billingAccountIdParameter = watch('billingAccountIdParameter');

  const selectedAccount = accounts?.find((account) => account.id === billingAccountIdParameter);

  useEffect(() => {
    if (!billingAccountIdParameter || !selectedAccount) return;
    dispatch(
      getPaymentInformation({
        mno: selectedAccount.mno,
        accountId: selectedAccount.id,
        currency: selectedAccount.amountDueLimitCurrency,
      })
    );
  }, [dispatch, billingAccountIdParameter, selectedAccount]);

  useEffect(() => {
    const accountsOfUser = getAccountsOfUserWithPaymentFunctionality(accounts, permissions);
    if (accountsOfUser && accountsOfUser.length) {
      const compare = (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      };
      setValue('billingAccountIdParameter', accountsOfUser.sort((a, b) => compare(a, b))[0].id);
    }
  }, [accounts, permissions]);

  function getAccountDropdownOptions() {
    const accountsOfUser = getAccountsOfUserWithPaymentFunctionality(accounts, permissions);
    const options = accountsOfUser?.map((account) => ({
      value: account.id,
      label: account.name === account.mno ? account.name : `${account.name} (${account.mno})`,
    }));
    return options ?? [];
  }

  function getTags() {
    const billingAccountId = getMergedPermissionAttribute(permissions, 'billingAccount');

    return {
      cpBillAccId: billingAccountId.toString(),
      mno_ids: convertMnoNamesToIDs(mnos, mnosOfUser).toString(),
    };
  }

  function getUrlParams() {
    return { billingAccountIdParameter };
  }

  return (
    <Root
      header={<PageHeader iconName="money" title="Account Balance" noHeaderButton />}
      content={
        <section className="p-48 w-full">
          <header className="flex flex-col md:flex-row w-full gap-48">
            <div className="min-w-[300px]">
              <FormInputDropdown
                name="billingAccountIdParameter"
                label="Billing Account"
                control={control}
                mno={mnosOfUser}
                options={getAccountDropdownOptions()}
                required
              />
            </div>
            {accountPaymentInformation && (
              <div className="flex flex-col gap-16">
                <p>
                  <strong>Amount Available for new Orders: </strong>
                  <span>
                    {accountPaymentInformationLoading ||
                    !accountPaymentInformation ||
                    !selectedAccount
                      ? 'Calculating the amount...'
                      : getStyledCurrencyNumber(
                          accountPaymentInformation.amountAvailable,
                          selectedAccount.amountDueLimitCurrency
                        )}
                  </span>
                </p>
                <p>
                  <strong>
                    Amount Reserved for Scheduled Orders (includes Orders executed today):{' '}
                  </strong>
                  <span>
                    {accountPaymentInformationLoading ||
                    !accountPaymentInformation ||
                    !selectedAccount
                      ? 'Calculating the amount...'
                      : getStyledCurrencyNumber(
                          accountPaymentInformation.authorizedAmount,
                          selectedAccount.amountDueLimitCurrency
                        )}
                  </span>
                </p>
              </div>
            )}
          </header>
          <div className="mt-72">
            {loadingMnos || !billingAccountIdParameter ? (
              <FuseLoading />
            ) : (
              <QuickSightFrame
                dashboardIdAttributeName={ATTRIBUTE_NAME_QS_DASHBOARD_ID_ACCOUNT_BALANCE_REPORT}
                tags={getTags()}
                urlParams={getUrlParams()}
              />
            )}
          </div>
        </section>
      }
      innerScroll
    />
  );
}

export default AccountBalanceReport;
