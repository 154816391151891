import { API } from 'aws-amplify';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import * as queries from '../../../../graphql/queries';
import * as mutations from '../../../../graphql/mutations';
import { showMessage } from '../../../store/fuse/messageSlice';

const logger = new ComponentLogger('AttributesSlice');

export const getAttributes = createAsyncThunk(
  'support/getAttributes',
  async (useAPIKeyAuth = false) => {
    try {
      const options = { query: queries.listAttributes };
      if (useAPIKeyAuth) {
        options.authMode = 'API_KEY';
        options.authToken = process.env.REACT_APP_APPSYNC_API_KEY;
      }
      const response = await API.graphql(options);
      console.log(response);
      return await response.data.listAttributes.items;
    } catch (error) {
      logger.error('getAttributes', { error });
      return [];
    }
  }
);

async function verifyUniqueNameAndType(attribute, dispatch) {
  const responseData = await API.graphql({
    query: queries.listAttributes,
    variables: {
      filter: {
        name: {
          eq: attribute.name,
        },
        type: {
          eq: attribute.type,
        },
      },
    },
  });
  if (responseData.data.listAttributes.items.length > 0) {
    dispatch(showMessage({ message: 'Attribute with same name exist!', variant: 'info' }));
    throw new Error('Attribute with same name exist');
  }
}

export const saveAttribute = createAsyncThunk(
  'support/saveAttribute',
  async (attribute, { dispatch }) => {
    let data;
    if (!attribute.id) {
      await verifyUniqueNameAndType(attribute, dispatch);
      const response = await API.graphql({
        query: mutations.createAttribute,
        variables: { input: attribute },
      }).catch((error) => {
        logger.error('createAttribute', { data: attribute, error });
      });

      data = await response.data.createAttribute;

      dispatch(
        showMessage({ message: 'Attribute has been created successfully!', variant: 'success' })
      );
    } else {
      const { createdAt, updatedAt, ...rest } = attribute;

      const response = await API.graphql({
        query: mutations.updateAttribute,
        variables: { input: rest },
      }).catch((error) => {
        logger.error('updateAttribute', { data: attribute, error });
      });

      data = await response.data.updateAttribute;
      dispatch(
        showMessage({ message: 'Attribute has been updated successfully!', variant: 'success' })
      );
    }
    return data;
  }
);

export const deleteAttribute = createAsyncThunk(
  'support/deleteAttribute',
  async (attribute, { dispatch }) => {
    const request = {
      id: attribute.id,
    };

    const response = await API.graphql({
      query: mutations.deleteAttribute,
      variables: { input: request },
    }).catch((error) => {
      logger.error('deleteAttribute', { data: attribute, error });
    });

    const data = await response.data.deleteAttribute;
    dispatch(
      showMessage({ message: 'Attribute has been deleted successfully!', variant: 'success' })
    );
    return data;
  }
);

const attributesAdapter = createEntityAdapter({});

export const { selectAll: selectAttributes, selectById: selectAttributeById } =
  attributesAdapter.getSelectors((state) => state.support.attributes);

export const attributesSlice = createSlice({
  name: 'support/attributes',
  initialState: attributesAdapter.getInitialState({
    loading: true,
    attributeDialog: {
      type: 'new',
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    openNewAttributeDialog: (state, action) => {
      state.attributeDialog = {
        type: 'new',
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewAttributeDialog: (state, action) => {
      state.attributeDialog = {
        type: 'new',
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditAttributeDialog: (state, action) => {
      state.attributeDialog = {
        type: 'edit',
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditAttributeDialog: (state, action) => {
      state.attributeDialog = {
        type: 'edit',
        props: {
          open: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [getAttributes.pending]: (state) => {
      state.loading = true;
    },
    [getAttributes.fulfilled]: (state, action) => {
      state.loading = false;
      attributesAdapter.setAll(state, action.payload);
    },
    [getAttributes.rejected]: (state, action) => {
      state.loading = false;
    },
    [saveAttribute.fulfilled]: (state, action) => {
      const attribute = action.payload;
      attributesAdapter.upsertOne(state, attribute);
    },
    [deleteAttribute.pending]: (state) => {
      state.loading = true;
    },
    [deleteAttribute.fulfilled]: (state, action) => {
      attributesAdapter.removeOne(state, action.payload.id);
      state.loading = false;
    },
  },
});

export const {
  openNewAttributeDialog,
  closeNewAttributeDialog,
  openEditAttributeDialog,
  closeEditAttributeDialog,
} = attributesSlice.actions;

export default attributesSlice.reducer;
