/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import history from '@history';
import { setInitialSettings, setDefaultSettings } from 'app/store/fuse/settingsSlice';
import { Auth } from '@aws-amplify/auth';
import { API, Amplify, AWSCloudWatchProvider } from 'aws-amplify';
import { roleNames, redirectUrlsByRole } from 'app/auth/authRoles';
import settingsConfig from 'app/fuse-configs/settingsConfig';
import LOGGER from 'app/services/logger/FlightdeckLogger';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import { UTILITY_ACTIONS_MAP } from 'app/services/constants';
import * as queries from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';
import { showMessage } from '../../store/fuse/messageSlice';
import { throwErrorIfHandledByMiddleware } from '../../store';
import { userHasOrderPermission, userHasBillingPermission, isSupportOrAdmin } from '../../services/permissionUtil';

const logger = new ComponentLogger('UserSlice');

export const setUserData = (cognitoData) => async (dispatch) => {
  try {
    const mimickedUser = JSON.parse(localStorage.getItem('mimickedUser'));

    const currentUserInfo = await Auth.currentUserInfo();
    const attributes = currentUserInfo.attributes
      ? currentUserInfo.attributes
      : cognitoData?.challengeParam?.userAttributes;
    const role = attributes['custom:role'];
    const roles = mimickedUser?.roles ?? (role ? role.split(',').map((r) => r.trim()) : []);
    const user = {
      role: roles,
      from: 'auth0',
      data: {
        displayName: cognitoData.username,
        email: attributes.email,
        phoneNumber: attributes.phone_number,
      },
    };
    if (cognitoData?.preferredMFA !== 'NOMFA' || process.env.NODE_ENV === 'development') {
      const promise = await Promise.all([
        Auth.currentUserCredentials(),
        fetchPermissions(user.data.email),
        fetchUserSettings(user.data.email),
        fetchUserNotifications(user.data.email),
      ]);
      const credentials = promise[0];
      const permissions = mimickedUser?.permissions ?? promise[1];
      const userSettings = mimickedUser?.userSettings ?? promise[2];
      const userNotifications = promise[3];

      addVirtualRolesFromPermissions(user, permissions);
      addVirtualRolesFromUserSettings(user, userSettings);

      if (!settingsConfig.loginRedirectUrl && user.loginRedirectUrl) {
        settingsConfig.loginRedirectUrl = user.loginRedirectUrl;
      } else if (!settingsConfig.loginRedirectUrl && !user.loginRedirectUrl) {
        settingsConfig.loginRedirectUrl = findRedirectUrl(user.role);
      }
      registerLogger(user?.data?.email);

      dispatch(
        setUser({
          ...user,
          permissions,
          userSettings,
          identityId: credentials.identityId,
          userNotifications,
        })
      );
      dispatch(setDefaultSettings(user.data.settings));
    }
  } catch (error) {
    logger.error('setUserData', { error });
    throwErrorIfHandledByMiddleware(error);
  }

  function registerLogger(email) {
    LOGGER.setUser(email);
    Amplify.register(LOGGER);
    LOGGER.addPluggable(new AWSCloudWatchProvider());
  }

  function addVirtualRolesFromPermissions(user, permissions) {
    if (permissions.length && user.role.includes(roleNames.USER)) {
      if (userHasOrderPermission(permissions)) {
        user.role.push(roleNames.USER_WITH_ORDER_PERMISSION);
        user.role.push(roleNames.USER_WITH_BILLING_PERMISSION);
      } else if (userHasBillingPermission(permissions))
        user.role.push(roleNames.USER_WITH_BILLING_PERMISSION);
    }
  }

  function addVirtualRolesFromUserSettings(user, userSettings) {
    if (userSettings) {
      addVirtualRole('searchMSISDNActivity', roleNames.USER_WITH_SEARCH_MSISDN_ACTIVITY);
      addVirtualRole(
        'viewProvisionedSubscribersReport',
        roleNames.USER_WITH_VIEW_PROVISIONED_SUBSCRIBERS
      );
      addVirtualRole('paymentFunctionalityEnabled', roleNames.USER_WITH_PAYMENT_FUNCTIONALITY);
      addVirtualRole('viewContactPolicyReport', roleNames.USER_WITH_CONTACT_POLICY_ACCESS);
    }

    function addVirtualRole(setting, roleName) {
      if (userSettings[setting]) {
        if (user.role) user.role.push(roleName);
        else user.role = [roleName];
      }
    }
  }
};

export const findRedirectUrl = (roles) => {
  if (roles.length) {
    for (const urlByRole of redirectUrlsByRole) {
      if (roles.includes(urlByRole.role)) return urlByRole.url;
    }
  }
  return '/401';
};

const fetchPermissions = async (username) => {
  try {
    const permissionData = await API.graphql({
      query: queries.listPermissions,
      variables: { username },
    });
    return permissionData.data.listPermissions.items;
  } catch (error) {
    logger.error('fetchPermissions', { error });
    return [];
  }
};

const fetchUserSettings = async (username) => {
  try {
    const userSettings = await API.graphql({
      query: queries.listUserSettings,
      variables: { username },
    });
    const { items } = userSettings.data.listUserSettings;
    return items && items.length ? items[0] : null;
  } catch (error) {
    logger.error('fetchUserSettings', { error });
    return {};
  }
};

const fetchUserNotifications = async (username) => {
  try {
    let response = await API.graphql({
      query: queries.listUserNotifications,
      variables: { username },
    });
    const { items } = response.data.listUserNotifications;
    const data = items && items.length ? items[0] : null;

    if (data == null) {
      const defaultUserNotification = {
        orderApprovalIsRequiredByMe: true,
        orderApprovedByMeCanceledOrTerminated: true,
        myOrderIsApprovedOrRejected: true,
        myOrderIsCancelledOrTerminated: true,
        myOrderIsCompleted: true,
        username,
      };

      response = await API.graphql({
        query: mutations.createUserNotification,
        variables: { input: defaultUserNotification },
      }).catch((error) => {
        logger.error('createUserNotification', { data: defaultUserNotification, error });
      });

      return response.data.createUserNotification;
    }

    return data;
  } catch (error) {
    logger.error('fetchUserNotifications', { error });
    return {};
  }
};

export const saveUserNotification = createAsyncThunk(
  'journey/userNotification',
  async (userNotification, { dispatch }) => {
    const { createdAt, updatedAt, ...userNotificationData } = userNotification;
    const response = await API.graphql({
      query: mutations.updateUserNotification,
      variables: { input: userNotificationData },
    }).catch((error) => {
      logger.error('saveUserNotification', { data: userNotificationData, error });
    });

    const data = await response.data.updateUserNotification;
    dispatch(
      showMessage({
        message: 'User notification has been updated successfully!',
        variant: 'success',
      })
    );

    return data;
  }
);

export const setMimickedUserRoles = createAsyncThunk(
  'auth/user/getServerUser',
  async (params, { dispatch }) => {
    const { username, setIsAlreadyMimicked } = params;
    if (!username) return '';
    try {
      dispatch(showMessage({ variant: 'success', message: 'Mimicked user is being added...' }));
      const userResponse = await API.graphql({
        query: queries.utility,
        variables: {
          action: UTILITY_ACTIONS_MAP.GET_USER_ROLES,
          data: JSON.stringify({
            username,
          }),
        },
      });
      const roles = JSON.parse(userResponse.data.utility.result).Value;

      if (isSupportOrAdmin(roles)) {
        dispatch(
          showMessage({ variant: 'error', message: 'You cannot mimic an admin or support user.' })
        );
        localStorage.removeItem('mimickedUser');
        return '';
      }
      setIsAlreadyMimicked(true);
      window.location.reload();
      return roles;
    } catch (err) {
      console.log('error fetching user settings', err);
      return '';
    }
  }
);

export const logoutUser = () => async (dispatch, getState) => {
  await Promise.all([Auth.signOut(), dispatch(setInitialSettings()), dispatch(userLoggedOut())]);
  history.push('/login');
};

const initialState = {
  role: [], // guest
  permissions: [],
  data: {
    displayName: '',
    email: '',
  },
};

const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (state, action) => action.payload,
    userLoggedOut: (state, action) => initialState,
  },
  extraReducers: {
    [saveUserNotification.fulfilled]: (state, action) => {
      state.userNotifications = action.payload;
    },
    [setMimickedUserRoles.fulfilled]: (state, action) => {
      if (!action.payload) return;
      const curr = JSON.parse(localStorage.getItem('mimickedUser'));
      curr.roles = action.payload.split(',');
      localStorage.setItem('mimickedUser', JSON.stringify(curr));
    },
  },
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
